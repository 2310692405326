import styled from '@emotion/styled';
import React from 'react';

const Chart = styled.div`
    border-bottom: 1px solid;
    border-left: 1px solid;
    height: 200px;
    width: 200px;
    padding: 0;
    position: relative;
`

const Line = styled.div`
    width: 231px;
    height: 0px;
    border-bottom: 1px solid red;
    -webkit-transform: translateY(117px) translateX(-34px) rotate(45deg);
    position: absolute;
`

const YLabels = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;
    margin-bottom: 25px;
`

const Figure = styled.figure`
    // display: flex;
    // justify-content: center;
`

const Inlined = styled.div`
    display: flex;
    justify-content: center;
    margin-left: -80px;
`

const XLabels = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const evolvabilityCouplingChart = () => {
    return <Figure>
        <Inlined>
            <YLabels>
                <div>More</div>
                <em>Evolvability</em>
                <div>Less</div>
            </YLabels>
            <div>
                <Chart>
                    <Line></Line>
                </Chart>
                <XLabels>
                    <div>Less</div>
                    <em>Coupling</em>
                    <div>More</div>
                </XLabels>
            </div>
        </Inlined>
    </Figure>
};

export default evolvabilityCouplingChart;
